@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;500&family=Poppins:wght@200&family=Roboto:wght@300&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    color: #F5F5F5;
}

body {
    display: flex;
    flex-wrap: wrap;
    background: #4b4b5d;
}

.App{
    width: auto;
    background-color: #1A1B21;
    border-radius: 10px;
    position: fixed;
}

img{
    width: 320px;
    height: 320px;
    border-radius: 10px 10px 0px 0px;
}

.info--det{
    text-align: center;
}

h2{
    font-weight: 700;
    font-size: 25px;
    margin: 5px 0 0 5px;
}
h3{
    font-size: 16px;
    line-height: 24px;
    margin: 15px 0 0 0;
}
h4{
    font-size: 12.8px;
    font-weight: 400;
    color: #F3BF99;
    margin-top: 5px;
    margin-bottom: 15px;
}
p{
    font-weight: 400;
    font-size: 10.24px;
    line-height: 15.36px;
    margin-bottom: 25px;
    color: #DCDCDC;
}

.abt,
.inter{
    width: 247px;
    margin-left: 20px;
    
}

.foot--{
    position: relative;
    height: 64px;
    background: #161619;
    border-radius: 0px 0px 10px 10px;
}


button{
width: 247px;
background:  #FFFFFF;
padding: 4px 10px 4px 10px;
border-radius: 6px;
border: 1px solid #D1D5DB;
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
color: black;
}

.icons{
    display: flex;
    justify-content: space-around;
    padding-top: 0.8rem;
    margin: 1.5rem;
}

@media screen and (max-width: 500px) {
    body{
        display: flex;
        max-height: fit-content;
        position: relative;
        left: 35px;
        top: 55px;
    }
}